<div class="popupHeader">
    <div class="align-items-center d-flex justify-content-between">
        <h4 mat-dialog-title class="mb-0 text-white requestText">Reject Data Access</h4>
        <button type="button" class="btn close text-white" aria-label="Close" (click)="closeDialog()">
            <span aria-hidden="true"><i class="material-icons">close</i></span>
        </button>
    </div>
    <div class="customBodyCss">
        <h5 mat-dialog-title class="mb-0 text-white text-center mb-4 mt-4 rejectText"> Are you sure you want to reject
            data access?
        </h5>
        <form [formGroup]="rejectForm" (ngSubmit)="onSubmitClick()">
            <mat-form-field class="example-full-width mb-4" appearance="outline">
                <mat-label>Comment</mat-label>
                <textarea matInput formControlName="comment" rows="4" [minLength]="2" [maxLength]="250"></textarea>
                <mat-error
                    *ngIf="!rejectForm.get('comment').hasError('required') && !rejectForm.get('comment').hasError('minLength')">
                    Comment should be between 2 - 250 characters
                </mat-error>

            </mat-form-field>
            <div class="btn-sec text-end">
                <button class="msr-3 text-white primaryBtn" mat-raised-button mat-button color="primary">
                    <span class="saveBtn">Save</span></button>
                <button type="button" mat-raised-button mat-button color="accent" (click)="closeDialog()">
                    <span class="cancelBtn">Cancel</span>
                </button>
            </div>
        </form>
    </div>
</div>