import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { HttpService } from "../../core/service/http.service";

@Injectable({
  providedIn: "root",
})
export class ProfileService {

  userDetails$ = new Subject();
  constructor(private httpService: HttpService) {}

  profileUpdate(userDetails: any, url: any) {
    return this.httpService.post(url, userDetails);
  }
  lockUnlock(userDetails) {
    return this.httpService.post("User/LockUnlock", userDetails);
  }
  activeDeactiveAdmin(userDetails) {
    return this.httpService.post("User/Admin/Delete", userDetails);
  }
}
