import { Component, OnInit } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "src/app/core/service/notification.service";
import { SettingService } from "src/app/setting/setting.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
@Component({
  selector: "app-refrence-privacy-policy",
  templateUrl: "./refrence-privacy-policy.component.html",
  styleUrls: ["./refrence-privacy-policy.component.scss"],
})
export class RefrencePrivacyPolicyComponent implements OnInit {
  privacyPolicy: any;
  tempPrivacyPolicy: any;
  constructor(
    private spinner: NgxSpinnerService,
    public settingService: SettingService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RefrencePrivacyPolicyComponent>
  ) {
    this.getPrivacyPolicy();
  }

  ngOnInit(): void {}

  getPrivacyPolicy() {
    this.spinner.show();
    var data = {
      Type: "Privacy Policy",
    };
    this.settingService.GetPrivacyAndPolicy(data).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          if (res.Data != null) {
            this.privacyPolicy = res.Data;
            this.tempPrivacyPolicy = this.privacyPolicy?.Description;

            this.spinner.hide();
          }
        } else if (res.Data.Errors !== undefined) {
          this.notificationService.showNotification(
            "snackbar-danger",
            res.Data.Errors[0].Message,
            "top",
            "center"
          );
          this.spinner.hide();
        }
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
