import { Event, Router } from "@angular/router";
import { Component, Inject } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";
import { NgxSpinnerService } from "ngx-spinner";
import { NotificationService } from "src/app/core/service/notification.service";
import { DashboardService } from "src/app/dashboard/dashboard.service";
import { Constants } from "src/app/config/constants";
import { RefrenceTermsOfServiceComponent } from "../refrence-terms-of-service/refrence-terms-of-service.component";
import { RefrencePrivacyPolicyComponent } from "../refrence-privacy-policy/refrence-privacy-policy.component";

@Component({
  selector: "app-add-request-modal",
  templateUrl: "./add-request-modal.component.html",
  styleUrls: ["./add-request-modal.component.scss"],
})
export class AddRequestModalComponent {
  selectedTeams: { Teams: number }[] = [];
  user: any;
  requestAccessForm: UntypedFormGroup;
  uncheckBox: boolean = true;
  isCheckboxChecked: boolean = true;
  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddRequestModalComponent>,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    public dashboardService: DashboardService,
    private dialogModel: MatDialog,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.user = JSON.parse(sessionStorage.getItem("CurrentUser"));
  }

  ngOnInit() {
    this.requestAccessForm = this.fb.group({
      email: [
        "",
        [
          Validators.required,
          Validators.pattern(Constants.EMAIL_REGEXP),
          Validators.minLength(4),
          Validators.maxLength(1000),
        ],
      ],
    });
  }

  get f() {
    return this.requestAccessForm.controls;
  }

  // request access
  requestAccess() {
    let data = {};
    let api: any;

    if (this.data.AccessKey !== "Dataccess") {
      (data = {
        UserId: this.data.UserId,
        ApprovalStatus: this.data.ApprovalStatus,
        Role: this.data.Role,
        ID: this.data.ID,
      }),
        (api = "User/ApprovalForCoach");
    } else {
      let EmailId = this.data.ConsumerEmailId;
      (data = {
        CoachUserId: this.user.ID,
        Consumers: [{ EmailId }],
        Message: "",
      }),
        (api = "User/ConsumerApprovalRequestForCoach");
    }
    this.spinner.show();
    this.dashboardService.sendRequestAccessForConsumer(data, api).subscribe({
      next: (res: any) => {
        if (!res.HasErrors) {
          this.spinner.hide();
          this.dialogRef.close(1);
          this.requestAccessForm.reset();
          this.notificationService.showNotification(
            "snackbar-success",
            "Status updated successfully",
            "top",
            "center"
          );
        } else {
          this.spinner.hide();
          this.notificationService.showNotification(
            "snackbar-danger",
            res.Errors[0].Message,
            "top",
            "center"
          );
        }
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  closeDialog(): void {
    this.dialog.closeAll();
    this.dialogRef.close(1);
  }

  goToTermsPage(data: any) {
    sessionStorage.setItem("Terms", data);
    this.openTermsPopup();
  }

  goToPrivacyage(data: any) {
    sessionStorage.setItem("Terms", data);
    this.openPrivacyPopup();
  }

  updateButtonState() {
    this.isCheckboxChecked = this.isCheckboxChecked;
  }

  updateButtonStates() {
    this.uncheckBox = this.uncheckBox;
  }

  openTermsPopup() {
    const dialogRef = this.dialogModel.open(RefrenceTermsOfServiceComponent, {
      width: "900px",
      panelClass: "dialog-container-custom",
      disableClose: true,
      height: "78vh",
    });
    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp == 1) {
        }
      },
    });
  }

  openPrivacyPopup() {
    const dialogRef = this.dialogModel.open(RefrencePrivacyPolicyComponent, {
      width: "900px",
      panelClass: "dialog-container-custom",
      disableClose: true,
      height: "78vh",
    });
    dialogRef.afterClosed().subscribe({
      next: (resp: any) => {
        if (resp == 1) {
        }
      },
    });
  }
}
