import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { environment } from "src/environments/environment";
import { HttpService } from "../core/service/http.service";

@Injectable({
  providedIn: "root",
})
export class SettingService {
  private dataSource: BehaviorSubject<string> = new BehaviorSubject<string>(
    "Initial Value"
  );
  data: Observable<string> = this.dataSource.asObservable();

  constructor(private http: HttpService, private httpClient: HttpClient) {}
  GetFAQData(FAQData) {
    return this.http.postAnonymous("FAQ/List", FAQData);
  }
  AddFAQData(FAQData) {
    return this.http.postAnonymous("FAQ/Add", FAQData);
  }
  GetByApp(FAQData) {
    return this.http.postAnonymous("FAQ/GetByApp", FAQData);
  }

  UpdateFAQData(FAQData) {
    return this.http.postAnonymous("FAQ/Update", FAQData);
  }

  DeactivateFAQData(FAQData) {
    return this.http.postAnonymous("FAQ/DectivateFAQ", FAQData);
  }
  ActivateFAQData(FAQData) {
    return this.http.postAnonymous("FAQ/ActivateFAQ", FAQData);
  }
  GetAllFAQSection(FAQData) {
    return this.http.postAnonymous("FAQ/GetAllFAQSection", FAQData);
  }

  AddFAQSection(FAQData) {
    return this.http.postAnonymous("FAQ/AddFAQSection", FAQData);
  }

  UpdateFAQSection(FAQData) {
    return this.http.postAnonymous("FAQ/UpdateFAQSection", FAQData);
  }

  ActivateFAQSection(FAQData) {
    return this.http.postAnonymous("FAQ/ActivateFAQSection", FAQData);
  }

  DeactivateFAQSection(FAQData) {
    return this.http.postAnonymous("FAQ/DeactivateFAQSection", FAQData);
  }

  GetPrivacyAndPolicy(PrivacyPolicy) {
    return this.http.postAnonymous("Master/GetLegalDocs", PrivacyPolicy);
  }
  AddPrivacyAndPolicy(PrivacyPolicy) {
    return this.http.postAnonymous("Master/AddLegalDocs", PrivacyPolicy);
  }
  GetTermsOfService(TermsService) {
    return this.http.postAnonymous("Master/GetLegalDocs", TermsService);
  }
  AddTermsOfService(TermsService) {
    return this.http.postAnonymous("Master/AddLegalDocs", TermsService);
  }
  AddFirmware(firmwareData: FormData) {
    var headers = new HttpHeaders();
    return this.httpClient.post(
      `${environment.master}/Master/AddFirmware`,
      firmwareData,
      { headers: headers }
    );
  }
  GetFirmwareList(firmwareData: any) {
    return this.http.postAnonymous(`Master/GetFirmwareList`, firmwareData);
  }
  PublishFirmware(firmwareData: any) {
    return this.http.postAnonymous(`Master/PublishFirmware`, firmwareData);
  }
}
