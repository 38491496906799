export class Constants {
  public static USERNAME = /^\S*$/;
  public static NAME_REGEXP = /^[A-Za-z' ]+$/;
  public static NUMBER_REGEXP = "^[a-zA-Z0-9]+$";
  public static EMAIL_REGEXP =
    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  public static EMPTY_STRING_REGEXP = /^(?!\s*$).+/;
  public static First_STRING_REGEXP = /^[a-zA-Z]+(?:('[a-zA-Z]+))?(\s[a-zA-Z]+(?:('[a-zA-Z]+))?)?$/;
  public static PHONE_NUMBER_REGEXP = /^[1-9][0-9]*$/;
  public static nameRegex = /^[a-zA-Z']+$/;
  public static nameRegexs = /^[A-Z][a-zA-Z']*([ \-][A-Z][a-zA-Z']*)*$/;
  public static PHONE_REGEXP = /^\d{9,16}$/;
  public static SPECIAL_CHAR_NUMBER = /^\d+$/;
  public static namesRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;

}
