<div class="delete-user">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-lg-6 col-xl-6 col-xxl-6  mx-auto">
                <div class="cssHelp">
                    <div class="text-white bgContent actualContent">
                        <p class="d-flex justify-content-center align-item-center fs-4">Are you sure, you want to
                            delete
                            account ?</p>

                        <p class="d-flex justify-content-center align-item-center fs-5">Please enter your email address
                            that you are trying to delete.</p>

                        <!-- 1st form contain email -->
                        <form [formGroup]="deleteUserAccount" *ngIf="emailForm">
                            <div class="emailContent">
                                <mat-form-field class="example-full-width m-2" appearance="outline">
                                    <mat-label>Email</mat-label>
                                    <input matInput [(ngModel)]="email" formControlName="email" [minLength]="2"
                                        [maxLength]="1000" required>
                                    <mat-error class=""
                                        *ngIf="deleteUserAccount.get('email').hasError('required')">Email is
                                        required</mat-error>
                                    <mat-error class=""
                                        *ngIf="deleteUserAccount.get('email').hasError('pattern')">Provide correct
                                        email</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="guideMessage ms-2">
                                <p class="fs-5">Please note that once you proceed with deleting the account, you will
                                    lose access to
                                    Calibrebio site and the mobile application. Proceed with caution.</p>
                            </div>
                            <div class="btn-sec text-end mr-1 mt-4">
                                <button class="text-white accountBtn" mat-raised-button mat-button color="primary"
                                    (click)="getOTP()">Get OTP</button>
                            </div>
                        </form>

                        <!-- 2nd form email and ng-OTP -->
                        <form *ngIf="otpForm">
                            <div class="email d-flex mb-3 fieldsInput">
                                <label for="" class="fw-normal emailTxt me-2">Email: </label>
                                <span>{{email}}</span>
                            </div>
                            <div class="emailContent mb-4 fieldsInput">
                                <label class="fw-normal emailTxt">OTP</label>
                                <ng-otp-input class="mb-1" (onInputChange)="onOtpChange($event)" [config]="{length:6}"
                                    (keypress)="dashboardService.ValidateNumeric($event)"></ng-otp-input>
                                <mat-error *ngIf="submitted && otpRequired">Please enter OTP</mat-error>
                                <span class="otp-timer">{{
                                    timeLeft !== "00:00" ? timeLeft : ""
                                    }}</span>
                                <a *ngIf="timeLeft==='00:00'" class="resend-btn cursor-pointer"
                                    (click)="getOTP()">Resend OTP</a>

                            </div>

                            <div class="guideMessage ms-2">
                                <p class="fs-5">Please note that once you proceed with deleting the account, you will
                                    lose access to
                                    Calibrebio site and the mobile application. Proceed with caution.</p>
                            </div>
                            <div class="btn-sec text-end mr-1 mt-4 mb-4">
                                <button class="text-white accountBtn" mat-raised-button mat-button color="primary"
                                    (click)="deleteAccounts()"> Delete
                                    Account</button>
                                <button type="button" mat-raised-button color="accent" class="ms-3" (click)="cancel()"
                                    mat-button>
                                    cancel</button>

                            </div>
                        </form>

                        <div *ngIf="!passedData" class="backToLogin text-center">
                            <a class="resend-btn cursor-pointer" (click)="goToLogin()">Back
                                to login</a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>