// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: "http://localhost:4200",

  //DEV APIs
  // api: "https://calibre-dev-user.azurewebsites.net/api/",
  // master: "https://calibre-dev-master.azurewebsites.net/api/",
  // device: "https://calibre-dev-device.azurewebsites.net/api/",
  // user: "https://calibre-dev-user.azurewebsites.net/api/",
  // security: "https://calibre-dev-security.azurewebsites.net/api/",

  //UAT APIs
  // api: "https://uat-cabibrebio-core.azurewebsites.net/api/",
  // master: "https://uat-calibre-master.azurewebsites.net/api/",
  // device: "https://uat-calibre-device.azurewebsites.net/api/",
  // user: "https://uat-cabibrebio-core.azurewebsites.net/api/",
  // security: "https://uat-calibre-security.azurewebsites.net/api/",

  // //PROD APIs
  api: "https://prod-calibrebio-core.azurewebsites.net/api/",
  master: "https://prod-calibrebio-master.azurewebsites.net/api/",
  device: "https://prod-calibrebio-device.azurewebsites.net/api/",
  user: "https://prod-calibrebio-core.azurewebsites.net/api/",
  security: "https://prod-calibrebio-security.azurewebsites.net/api/",

  firebaseConfig: {
    apiKey: "AIzaSyCka29BsBH2Lo-AOMcDlAOAjRja5WAa378",
    authDomain: "calibre-cbaa0.firebaseapp.com",
    projectId: "calibre-cbaa0",
    storageBucket: "calibre-cbaa0.appspot.com",
    messagingSenderId: "394369639364",
    appId: "1:394369639364:web:f90370e9b0e7276a7cfa91",
    measurementId: "G-0TEZQP77HB",
  },
  // api: "http://localhost:7071/api/",
  // master: "http://localhost:7071/api/",
  // device: "http://localhost:7071/api/",
  // user: "http://localhost:7071/api/",
  // security: "http://localhost:7071/api/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
