import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { Constants } from "../../../config/constants";
import { NotificationService } from "../../../core/service/notification.service";
import { DashboardService } from "../../dashboard.service";

@Component({
  selector: 'app-reject-data-access',
  templateUrl: './reject-data-access.component.html',
  styleUrls: ['./reject-data-access.component.sass']
})
export class RejectDataAccessComponent implements OnInit {
  constants: any;
  rejectForm: UntypedFormGroup;
  currentUserId: any;
  records: any[];
  getEditFormData = {
    comment: "",
  };

  constructor(
    private fb: UntypedFormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<RejectDataAccessComponent>,
    private spinner: NgxSpinnerService,
    private notificationService: NotificationService,
    public dashboardService: DashboardService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.constants = Constants;
    this.currentUserId = sessionStorage.getItem("UserId");
  }

  ngOnInit(): void {
    this.rejectForm = this.fb.group({
      comment: [
        "",
        [
          Validators.pattern(Constants.EMPTY_STRING_REGEXP),
          Validators.minLength(2),
          Validators.maxLength(250),
        ],
      ],

    });
  }

  // close dialog box
  closeDialog(): void {
    this.dialog.closeAll();
  }

  // form submit of reject access
  onSubmitClick() {
    if (this.rejectForm.valid) {
      this.getEditFormData.comment = this.rejectForm.get("comment").value;
      var data = {
        UserId: this.currentUserId,
        Role: "coach",
        ApprovalStatus: "Rejected",
        ID: this.data.CoachId,
        Message: this.getEditFormData.comment,

      };
      this.spinner.show();
      this.dashboardService.ApprovalForCoach(data).subscribe({
        next: (res: any) => {
          if (res != null) {
            this.records = res.Rows;
            this.spinner.hide();
            this.dialogRef.close(1);
            this.dashboardService.accessDenied(res.HasErrors === false);
            this.notificationService.showNotification(
              "snackbar-success",
              "Data access rejected successfully",
              "top",
              "center"
            );
            this.dashboardService.sendTriggerForRejectAccess('1');
          } else {
            this.notificationService.showNotification(
              "snackbar-danger",
              res.Errors[0].Message,
              "top",
              "center"
            );
          }
        },
        error: (error) => {
          this.spinner.hide();
        },
      });
    }
  }
}
