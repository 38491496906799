<div>
  <div class="align-items-center d-flex justify-content-between popupHeader">
    <h4 mat-dialog-title class="mb-0 text-white requestText"> Register As Coach</h4>
    <button type="button" class="btn close text-white" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true"><i class="material-icons">close</i></span>
    </button>
  </div>
  <div class="device-container">
    <form [formGroup]="CoachRegisterForm" (ngSubmit)="onSubmitClick()">
      <p class="text-white text-center">You are trying to register yourself as a coach,<br>
        this request will be sent to admin of Calibre and <br>
        on approval after review you will be assigned Coach role access</p>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>Message</mat-label>
        <textarea matInput #matTextarea formControlName="textmessage" rows="4" class="inputText" [minLength]="2"
          [maxLength]="250" required></textarea>
        <mat-error *ngIf="CoachRegisterForm.get('textmessage').hasError('required')">
          Message is required
        </mat-error>
        <mat-error
          *ngIf="!CoachRegisterForm.get('textmessage').hasError('required') && !CoachRegisterForm.get('textmessage').hasError('minLength')">
          Message should be between 2 - 250 characters
        </mat-error>
      </mat-form-field>

      <div class="btn-sec text-center">
        <button type="submit" class="msr-3 text-white primaryBtn" mat-raised-button mat-button color="primary"> <span
            class="saveBtn">Confirm</span></button>
        <button type="button" mat-raised-button mat-button color="accent" (click)="closeDialog()">
          <span class="cancelBtn">Cancel</span></button>
      </div>
    </form>
  </div>
</div>