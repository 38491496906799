export class PageCriteria {
  PageNumberToFetch: number;
  PageSize: number;
  SortOrder: string;
  SortColumn: string;

  constructor() {
    this.PageNumberToFetch = 0;
    this.PageSize = 10;
    this.SortOrder = "Desc";
    this.SortColumn = "";
  }
}
