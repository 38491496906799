<div class="popupHeader accessPopup p-3">
    <div mat-dialog-title class="mb-0">
        <div class="align-items-center d-flex justify-content-between stricky mb-0">
            <h4 class="mb-0 text-white requestText">Privacy Policy</h4>
            <button type="button" class="btn close text-white" aria-label="Close" (click)="closeDialog()">
                <span aria-hidden="true"><i class="material-icons">close</i></span>
            </button>
        </div>
    </div>
    <div class="rowDivider pb-2"></div>
    <div mat-dialog-content>
        <div class="text-white pt-3 bgContent innerText" [innerHTML]="tempPrivacyPolicy"></div>
    </div>
</div>