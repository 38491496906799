<div>
  <div class="align-items-center d-flex justify-content-between popupHeader">
    <h4 mat-dialog-title class="mb-0 text-white requestText">{{popupName}} </h4>
    <button type="button" class="btn close text-white" aria-label="Close" (click)="closeDialog()">
      <span aria-hidden="true"><i class="material-icons">close</i></span>
    </button>
  </div>
  <div class="device-container">
    <form [formGroup]="resetPasswordForm">

      <mat-form-field class="example-full-width mb-4" appearance="outline">
        <mat-label>New Password</mat-label>
        <input [type]="hide ? 'password' : 'text'" formControlName="newPassword" #password matInput required
          autocomplete="off" />
        <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hide">
          <mat-icon class="visibility">{{hide ? 'visibility_off' :
            'visibility'}}</mat-icon>
        </button>
        <mat-error class="" *ngIf="resetPasswordForm.get('newPassword').hasError('required')">
          New password required.
        </mat-error>
        <mat-error class=""
          *ngIf="!resetPasswordForm.get('newPassword').hasError('required') && resetPasswordForm.get('newPassword').hasError('pattern')">
          password must contain a minimum of 8 or a maximum
          of 15 characters. It also must include uppercase,
          lowercase, special characters, and numbers.
        </mat-error>
      </mat-form-field>

      <mat-form-field class="example-full-width mb-4" appearance="outline">
        <mat-label>Confirm Password</mat-label>
        <input [type]="hides ? 'password' : 'text'" formControlName="confirmPassword" #password matInput required
          autocomplete="off" />
        <button mat-icon-button matSuffix (click)="hides = !hides" [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="hides">
          <mat-icon class="visibility">{{hides ? 'visibility_off' :
            'visibility'}}</mat-icon>
        </button>
        <mat-error class="" *ngIf="resetPasswordForm.get('confirmPassword').hasError('required')">
          Confirm password required.
        </mat-error>
        <mat-error class="" *ngIf="resetPasswordForm.get('confirmPassword').hasError('matching')">
          Confirm password does not match with new password.
        </mat-error>
        <mat-error class="" *ngIf="resetPasswordForm.get('confirmPassword').hasError('pattern')">
          Confirm password does not match with new password.
        </mat-error>
      </mat-form-field>

      <div class="btn-sec text-end mt-2">
        <button type="submit" class="msr-3 text-white primaryBtn" mat-raised-button mat-button color="primary"
          (click)="onSubmitClick()"><span class="saveBtn">Save</span></button>
        <button type="button" mat-raised-button mat-button color="accent" (click)="closeDialog()">
          <span class="cancelBtn">Cancel</span></button>
      </div>
    </form>
  </div>
</div>