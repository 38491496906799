import { FooterComponent } from "./layout/footer/footer.component";
import { NgModule } from "@angular/core";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./layout/header/header.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { PageLoaderComponent } from "./layout/page-loader/page-loader.component";
import { AuthLayoutComponent } from "./layout/app-layout/auth-layout/auth-layout.component";
import { MainLayoutComponent } from "./layout/app-layout/main-layout/main-layout.component";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { fakeBackendProvider } from "./core/interceptor/fake-backend";
import { ErrorInterceptor } from "./core/interceptor/error.interceptor";
import { JwtInterceptor } from "./core/interceptor/jwt.interceptor";
import { ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatTabsModule } from "@angular/material/tabs";
import { NgSelectModule } from "@ng-select/ng-select";

import { ClickOutsideModule } from "ng-click-outside";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { WINDOW_PROVIDERS } from "./core/service/window.service";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { AuthModule } from "@auth0/auth0-angular";
import { environment } from "src/environments/environment";

import * as firebase from "firebase/app";
import { getPerformance } from "firebase/performance";
import { AngularFireModule } from "@angular/fire/compat";
// import { CountdownModule } from 'ngx-countdown';
import { NgOtpInputModule } from "ng-otp-input";

import {
  AngularFirePerformanceModule,
  PerformanceMonitoringService,
} from "@angular/fire/compat/performance";
import { AuthHttpInterceptor } from "@auth0/auth0-angular";
import { MemberAccountDeleteComponent } from "./member-account-delete/member-account-delete.component";
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    PageLoaderComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
    FooterComponent,
    MemberAccountDeleteComponent,
  ],
  imports: [
    // CountdownModule,
    NgOtpInputModule,
    BrowserModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    ScrollingModule,
    MatTabsModule,
    MatDialogModule,
    ClickOutsideModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    CoreModule,
    SharedModule,
    MatIconModule,
    AuthModule.forRoot({
      // DEV AND UAT DOMAIN
      // domain: "calibrebio-dev.us.auth0.com",
      // clientId: "70LeXbH7UbAlwkbWpN7rj7Tu18Kh52HJ",
      // PROD DOMAIN
      domain: "calibrebio.us.auth0.com",
      clientId: "lhcLHTkMprjOxD1qbYbwhkCYTCcyBvpm",
      // Request this audience at user authentication time
      // UAT AND DEV
      // audience: "https://calibrebio-dev.us.auth0.com/api/v2/",
      // PROD
      audience: "https://calibrebio.us.auth0.com/api/v2/",
      // Request this scope at user authentication time
      scope: "read:current_user",
      // Specify configuration for the interceptor
      httpInterceptor: {
        allowedList: [
          {
            // Match any request that starts 'https://calibrebio-dev.us.auth0.com/api/v2/' (note the asterisk)
            // UAT AND DEV
            // uri: "https://calibrebio-dev.us.auth0.com/api/v2/*",
            // PROD
            uri: "https://calibrebio.us.auth0.com/api/v2/*",
            tokenOptions: {
              // The attached token should target this audience
              // UAT AND DEV
              // audience: "https://calibrebio-dev.us.auth0.com/api/v2/",
              // PROD
              audience: "https://calibrebio.us.auth0.com/api/v2/",
              // The attached token should have these scopes
              scope: "read:current_user",
            },
          },
        ],
      },
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirePerformanceModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    fakeBackendProvider,
    WINDOW_PROVIDERS,
    // {
    //     provide: MatDialogRef,
    //     useValue: {},
    // },
    PerformanceMonitoringService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  app: any;
  constructor() {
    this.app = firebase.initializeApp(environment.firebaseConfig);

    const perf = getPerformance(this.app);
  }
}
