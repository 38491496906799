import { Component, OnInit } from "@angular/core";
import { Event, Router, NavigationStart, NavigationEnd } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "@auth0/auth0-angular";
import { Location } from "@angular/common";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  currentUrl: string;
  metadata = {};
  constructor(
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService,
    public auth: AuthService,
    private http: HttpClient,
    public router: Router,
    private locations: Location
  ) {
    this._router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.spinner.show();
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf("/") + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.spinner.hide();
      }
      window.scrollTo(0, 0);
    });

    // Listen for changes to localStorage
    window.addEventListener("storage", (event) => {
      if (event.key === "account-deleted" && event.newValue === "true") {
        // Perform logout action
        this.logout();
      }
    });
  }

  ngOnInit() {
  }

  // logout method
  logout() {
    this.auth.logout();
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(["/authentication/login"]);
  }
}
