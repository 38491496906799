<div class="popupHeader accessPopup" style="--mat-dialog-transition-duration: none;">
  <h2 class="mb-0 text-white">
    {{data.AccessKey ? 'Request Data Access' : !data.AccessKey? 'Accept Data Request':''}}</h2>
  <div class="rowDivider"></div>
  <div class="row mt-3">
    <div class="col-12" *ngIf="data.AccessKey">
      <p class="text-white text-center fs-5 p-3">
        You are requesting access to a member's session data. Prior to proceeding, please review and accept the Terms of
        Use and Privacy Policy.
      </p>
    </div>
    <div class="col-12" *ngIf="!data.AccessKey">
      <p class="text-white text-center ms-4 fs-5 p-3">
        You are granting access to your session data. Prior to proceeding, please review and accept the Terms of Use and
        Privacy Policy.
      </p>
    </div>
  </div>
  <div class="row ms-4 mt-3">
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <input type="checkbox" [(ngModel)]="isCheckboxChecked" (change)="updateButtonState()">
      <label class="form-check-label" for="flexCheckChecked">

        <a class="text-white" (click)="goToTermsPage('TermsOfService')">Terms
          of Use</a>
      </label>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <input type="checkbox" [(ngModel)]="uncheckBox" (change)="updateButtonStates()">
      <label class="form-check-label" for="flexCheckChecked">
        <a class="text-white" (click)="goToPrivacyage('PrivacyPolicy')">Privacy Policy</a>
      </label>
    </div>
  </div>

  <h4 class="mat-error ms-3 mt-3">
    *On clicking continue, it will be assumed you have read and accepted Terms of Use and Privacy Policy.
  </h4>
  <div class="btn-sec text-end mt-3">
    <button (click)="requestAccess()" [disabled]="!uncheckBox || !isCheckboxChecked"
      [ngClass]="!uncheckBox || !isCheckboxChecked ? 'disabledBtn':''" class="msr-3 text-white logFileBtn"
      mat-raised-button mat-button color="primary">
      <span class="saveBtn">Continue</span>
    </button>
    <button (click)="closeDialog()" type="button" mat-raised-button mat-button color="accent">
      <span class="cancelBtn">Cancel</span>
    </button>
  </div>
</div>